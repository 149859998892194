<template>
  <div class="add-policy-box">
    <classification-box title="设置权限" ref="float-box">
      <template slot="head-right">
        <el-button type="primary" size="small" @click="onEdit">保存</el-button>
        <el-button type="primary" size="small" @click="onBack" plain>返回</el-button>
      </template>
      <template>
        <div>
          <div class="model-box">
            <div class="model-box-title">角色名称</div>
            <div class="model-box-view">{{ titleName }}</div>
          </div>
          <div class="model-box">
            <div class="model-box-title">功能权限</div>
            <div class="model-box-view">
              <el-tree
                ref="routeTree"
                :data="routeTreeData"
                @check-change="handleChangeRouterTree"
                show-checkbox
                default-expand-all
                node-key="id"
                :props="defaultProps"
              ></el-tree>
            </div>
          </div>
          <div class="model-box">
            <div class="model-box-title">数据权限</div>
            <div class="model-box-view">
              <el-select
                v-model="value"
                @change="handleAuthorityChange"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div style="margin-top:1em" v-if="value == 5">
                <el-tree
                  :data="treeData"
                  style="width: 500px"
                  node-key="id"
                  default-expand-all
                  :expand-on-click-node="false"
                >
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ data.title }}</span>
                    <span
                      style="display: flex;justify-content: space-between;width: 204px;"
                    >
                      <div
                        :class="{'radio-box': true, 'radio-box-checkd': data.value == 1, 'radio-box-disabled': data.disabled}"
                        @click.stop="handleRadioChange(1,data,data.disabled)"
                      >
                        <div class="radio-box-r"></div>
                        <div class="radio-box-t">本部门</div>
                      </div>
                      <div
                        :class="{'radio-box': true, 'radio-box-checkd': data.value == 2}"
                        v-if="data.hasChildren"
                        @click.stop="handleRadioChange(2,data,data.value)"
                      >
                        <div class="radio-box-r"></div>
                        <div class="radio-box-t">本部门及下属</div>
                      </div>
                      <!-- <el-radio-group
                        v-model="data.value"
                        @change="handleRadioChange($event,data,data.value)"
                      >
                        <el-radio :label="1" @click="handleaaa(1)">本部门</el-radio>
                        <el-radio
                          :label="2"
                          @click="handleaaa(2)"
                          v-if="data.hasChildren"
                        >本部门及下属</el-radio>

                      </el-radio-group>-->
                      <!-- v-if="data.children && data.children.length" -->
                    </span>
                  </span>
                </el-tree>
              </div>
            </div>
          </div>
          <div class="model-box">
            <div class="model-box-title">字段权限</div>
            <div class="model-box-view">
              <el-select v-model="tableOptionsValue" placeholder="请选择">
                <el-option
                  v-for="item in tableOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div style="margin-top:1em">
                <el-table
                  :data="filterTableList"
                  style="width: 100%;margin-bottom: 20px;"
                  row-key="code"
                  :span-method="arraySpanMethod"
                  border
                  default-expand-all
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                >
                  <el-table-column prop="label" label="功能模块" width="180"></el-table-column>
                  <el-table-column
                    prop="fieldListOfView"
                    label="允许查看字段"
                    align="center"
                    width="180"
                  >
                    <template slot-scope="{row}">
                      <div
                        class="table-class"
                        @click="handleFieldList(`【${row.label}】- 查看字段设置`, 'fieldListOfView', 'hiddenFieldOfView', row)"
                      >
                        {{ row.fieldListOfView && row.fieldListOfView.length ? (row.hiddenFieldOfView && row.hiddenFieldOfView.length ? row.fieldListOfView.filter(val=> row.hiddenFieldOfView && !row.hiddenFieldOfView.includes(val.name)).map(val=> val.desc).toString(): '全部') : '无' }}
                        <i
                          class="el-icon-edit-outline"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="fieldListOfAdd"
                    align="center"
                    label="允许新增字段"
                  >
                    <template slot-scope="{row}">
                      <div
                        class="table-class"
                        @click="handleFieldList(`【${row.label}】- 添加字段设置`, 'fieldListOfAdd', 'hiddenFieldOfAdd', row)"
                      >
                        {{ row.fieldListOfAdd && row.fieldListOfAdd.length ? (row.hiddenFieldOfAdd && row.hiddenFieldOfAdd.length ? row.fieldListOfAdd.filter(val=> row.hiddenFieldOfAdd && !row.hiddenFieldOfAdd.includes(val.name)).map(val=> val.desc).toString(): '全部') : '无' }}
                        <i
                          class="el-icon-edit-outline"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="fieldListOfEdit"
                    align="center"
                    label="允许编辑字段"
                  >
                    <template slot-scope="{row}">
                      <div
                        class="table-class"
                        @click="handleFieldList(`【${row.label}】- 编辑字段设置`, 'fieldListOfEdit', 'hiddenFieldOfEdit', row)"
                      >
                        {{ row.fieldListOfEdit && row.fieldListOfEdit.length ? (row.hiddenFieldOfEdit && row.hiddenFieldOfEdit.length ? row.fieldListOfEdit.filter(val=> row.hiddenFieldOfEdit && !row.hiddenFieldOfEdit.includes(val.name)).map(val=> val.desc).toString() : '全部' ) : '无' }}
                        <i
                          class="el-icon-edit-outline"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </template>
    </classification-box>
    <listFieldDialog
      :dialogVisible.sync="listFieldVisible"
      :title="listFieldVisibleTitle"
      :listFieldInfo="dialogListFieldInfo"
      :checkboxIds="checkboxIdList"
      @changeHidelistField="changeHidelistField"
    />
  </div>
</template>

<script>
import ClassificationBox from "@/views/policyManagement/components/classificationBox.vue";
import listFieldDialog from "@/views/SystemManagement/components/SetPermissions/listFieldDialog.vue";
import { listFieldInfo, getOrganizationTree, grantDataScope, getGrantedDataScope } from "@/api/SystemData";
export default {
  components: { ClassificationBox, listFieldDialog },
  data() {
    return {
      treeData: [],
      routeTreeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      options: [{
        value: 1,
        label: '全部'
      }, {
        value: 2,
        label: '本人'
      }, {
        value: 3,
        label: '本部门'
      }, {
        value: 4,
        label: '本部门及下属'
      }, {
        value: 5,
        label: '自定义'
      }],
      value: '',
      tableData: [],
      tableOptions: [],
      listField: [],
      firstMenuId: '',
      tableOptionsValue: '',
      listFieldVisible: false,
      listFieldVisibleTitle: '',
      dialogListFieldInfo: [],
      checkboxIdList: [],
      CachingData: {},
      titleName: '',
      id: '',
      hiddenFieldOfList: []
    };
  },
  created() {
    this.routeTreeData = this.formattingRouter(this.$store.state.userRouter.children, []);
    this.firstMenuId = this.$store.state.userRouter.id;
    this.titleName = this.$route.params.name;
    this.id = this.$route.params.id;
    this.getUserRole();
  },
  computed: {
    filterTableList() {
      if (this.tableOptionsValue) {
        return JSON.parse(JSON.stringify(this.tableData.filter(val => val.code === this.tableOptionsValue)));
      } else {
        return JSON.parse(JSON.stringify(this.tableData));
      }
    }
  },
  methods: {
    getUserRole() {
      getGrantedDataScope({ roleId: this.id }).then(res => {
        this.value = res.data.scopeType;
        this.$refs.routeTree.setCheckedKeys(res.data.menuIds);
        this.hiddenFieldOfList = res.data.fieldScopes;
        if (res.data.scopeType == 5) {
          this.handleAuthorityChange(5, res.data.deptIds);
        }
        listFieldInfo().then(res => {
          this.listField = res.data;
          this.handleChangeRouterTree();
        });
      }).catch(err => {
        console.log(err);
      });
    },
    onEdit() {
      let obj = {
        roleId: this.id,
        scopeType: this.value,
        menuIds: this.$refs.routeTree.getCheckedNodes().map(val => val.id)
      };
      if (obj.scopeType == 5) {
        obj.deptIds = this.stringTreeData(this.treeData, []);
      }
      if (this.tableData && this.tableData.length) {
        obj.fieldScopes = this.SubmitTableData(this.tableData, []);
      }
      grantDataScope(obj).then(res => {
        if (res.success === true) {
          this.$message.success('保存成功！');
          this.onBack();
        } else {
          this.$message.warning(res.msg || '操作异常！');
        }
      }).catch(err => {
        console.log(err);
      });
    },
    onBack() {
      this.$router.go(-1);
    },
    handleSelectionChange(e) {
    },
    stringTreeData(tree, list) {
      tree.map(val => {
        if (val.value == 1 || val.value == 2) {
          list.push(val.id);
        }
        if (val.children && val.children.length) {
          this.stringTreeData(val.children, list);
        }
      });
      return list;
    },
    SubmitTableData(tree, list) {
      tree.map(val => {
        if (val.hiddenFieldOfAdd || val.hiddenFieldOfEdit || val.hiddenFieldOfView) {
          list.push({
            resourceCode: val.resourceCode,
            hiddenFieldOfAdd: val.hiddenFieldOfAdd && val.hiddenFieldOfAdd.length ? val.hiddenFieldOfAdd.toString() : '',
            hiddenFieldOfEdit: val.hiddenFieldOfEdit && val.hiddenFieldOfEdit.length ? val.hiddenFieldOfEdit.toString() : '',
            hiddenFieldOfView: val.hiddenFieldOfView && val.hiddenFieldOfView.length ? val.hiddenFieldOfView.toString() : '',
          });
        }
        if (val.children && val.children.length) {
          this.SubmitTableData(val.children, list);
        }
      });
      return list;
    },
    handleRadioChange(v, e) {
      if (e.disabled) {
        return false;
      }
      if (v == e.key) {
        e.value = '';
        e.key = '';
        this.handleForArr('', e);
      } else if (v == 2) {
        e.key = v;
        e.value = v;
        this.handleForArr(v, e);
      } else if (v == 1) {
        e.key = v;
        e.value = v;
        if (e.children && e.children.length) {
          this.handleForArr('', e);
        }
      }
    },
    handleForArr(v, e) {
      for (let index = 0; index < e.children.length; index++) {
        if (e.children[index].children && e.children[index].children.length) {
          e.children[index].value = v;
          e.children[index].key = v;
          this.handleForArr(v, e.children[index]);
        } else {
          e.children[index].disabled = v == 2 ? true : false;
          e.children[index].value = v == 2 ? 1 : v;
          e.children[index].key = v == 2 ? 1 : v;
        }
      }
    },
    formattingRouter(router, list) {
      return router.map(val => {
        return {
          id: val.id,
          label: val.name,
          code: val.code,
          parentId: val.parentId,
          children: val.children ? this.formattingRouter(val.children) : null
        };
      });
    },
    async handleChangeRouterTree() {
      let tableData = this.$refs.routeTree.getCheckedNodes(false, true);
      let tableDataIds = tableData.map(val => val.id);
      let childrenIds = [];
      tableData.map((item, index) => {
        let indexFlag = this.listField.findIndex(val => val.resourceCode === tableData[index].code || (val.resourceCode == 'M20' && tableData[index].code == 'M20-list') || (val.resourceCode == 'M21' && tableData[index].code == 'M21-list'));
        if (tableData[index].code.indexOf('_1') > -1 && tableData[index].label.indexOf('-工程机械险') == -1) {
          tableData[index].label += '-工程机械险'
        }else if (tableData[index].code.indexOf('_2') > -1 && tableData[index].label.indexOf('-车险') == -1) {
          tableData[index].label += '-车险'
        }else if (tableData[index].code.indexOf('_3') > -1 && tableData[index].label.indexOf('-非设备类财产险管理') == -1) {
          tableData[index].label += '-非设备类财产险管理'
        }
        if (indexFlag > -1) {
          Object.assign(tableData[index], this.listField[indexFlag]);
        }
        if (this.hiddenFieldOfList && this.hiddenFieldOfList.length) {
          let hideInd = this.hiddenFieldOfList.findIndex(val => val.resourceCode == tableData[index].resourceCode);
          if (hideInd > -1) {
            Object.assign(tableData[index], this.hiddenFieldOfList[hideInd]);
          }
        }
      });

      tableData = JSON.parse(JSON.stringify(tableData));
      tableData = await this.handleSomefield(tableData);
      // tableData = tableData.filter(val => val.fieldListOfAdd || val.fieldListOfView || val.fieldListOfEdit);
      // console.log(tableData2);
      // tableData = JSON.parse(JSON.stringify(tableData2)).filter(val => val.parentId === this.firstMenuId);
      // console.log(tableData);
      for (let index = 0; index < tableData.length; index++) {
        tableData[index].indexs = [index];
        tableData[index].children ? tableData[index].children = this.filterTableData(tableData[index], tableDataIds, [index], childrenIds) : '';
      }
      this.tableData = tableData.filter(item => !childrenIds.includes(item.id));
      // .filter(val => val.parentId === this.firstMenuId)
      this.tableOptions = this.tableData.map(val => {
        return {
          label: val.label,
          value: val.code
        };
      });
      this.tableOptions.unshift({
        value: '',
        label: '全部'
      });
    },
    filterTableData(item, ids, parentInd, childrenIds) {
      let list = [];
      item.children.map((val, ind) => {
        val.indexs = [...parentInd, ind];
        val.dnd = ind;
        if (val.children) {
          val.children = this.filterTableData(val, ids, [...parentInd, ind], childrenIds);
        }
        if (ids.includes(val.id)) {
          list.push(val);
          childrenIds.push(val.id);
        }
      });
      return list;
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(row.parentId, this.firstMenuId);
      // if (row.parentId === this.firstMenuId) {
      //   if (columnIndex) {
      //     return [0, 0];
      //   } else {
      //     return [1, 4];
      //   }
      // }
    },
    handleFieldList(title, fieldListOf, hiddenFieldOf, item, column) {
      if (!item[fieldListOf] || !item[fieldListOf].length) {
        this.$message.warning('该选项暂无添加权限');
        return false;
      }
      this.listFieldVisibleTitle = title;
      this.dialogListFieldInfo = item[fieldListOf];
      this.checkboxIdList = item[hiddenFieldOf];
      this.CachingData = {
        indexs: item.indexs,
        hiddenFieldOf
      };
      this.listFieldVisible = true;
    },
    changeHidelistField(e) {
      let item = this.tableData;
      for (let index = 0; index < this.CachingData.indexs.length; index++) {
        // if (index == 0) {
        //   item = item[this.CachingData.indexs[index]].children;
        // } else if (index + 1 < this.CachingData.indexs.length) {
        //   item = item[this.CachingData.indexs[index]].children;
        // } else {
        item[this.CachingData.indexs[index]][this.CachingData.hiddenFieldOf] = e;
        // }
      };
      let aa = this.tableOptionsValue;
      this.tableOptionsValue = null;
      this.$nextTick(() => {
        this.tableOptionsValue = aa;
      });
      // this.CachingData.item[this.CachingData.hiddenFieldOf] = e;
    },
    handleAuthorityChange(e, ids) {
      if (e == 5) {
        getOrganizationTree({
          current: 1,
          size: 9999,
          tenantId: this.$store.state.tenantId === '000000' ? this.$route.params.tenantId : this.$store.state.tenantId
        }).then(res => {
          if (ids) {
            this.treeData = this.initTreeData(res.data, ids);
          } else {
            this.treeData = res.data;
          }
        });
      }
    },
    initTreeData(data, ids) {
      return data.map(val => {
        if (ids.includes(val.id)) {
          if (val.children && val.children.length) {
            this.handleRadioChange(2, val);
          } else {
            this.handleRadioChange(1, val);
          }
        } else if (val.children && val.children.length) {
          this.initTreeData(val.children, ids);
        }
        return val;
      });
    },
    handleSomefield(list) {
      return new Promise((resolve, reject) => {
        let data = list.filter(val => val.fieldListOfAdd || val.fieldListOfView || val.fieldListOfEdit);
        data.map(val => {
          if (val.children) {
            val.children = val.children.filter(val => val.fieldListOfAdd || val.fieldListOfView || val.fieldListOfEdit);
          }
        });
        resolve(data);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.add-policy-box {
  margin: 12px;
  background-color: #fff;
  border-radius: 8px;
  .from-box-insurer2 {
    display: flex;
    &-left {
      flex: 1;
    }
  }
  .pdf-b {
    font-size: 14px;
    color: #0080ff;
    margin-left: 24px;
    cursor: pointer;
    border-bottom: 1px solid #0080ff;
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: #fff;
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
  .el-button--danger.is-plain {
    color: #c94242;
    background-color: #fff;
    border-color: #c94242;
  }
  .model-box {
    display: flex;
    margin-bottom: 2em;
    &-title {
      font-size: 14px;
      color: #333333;
      min-width: 68px;
      margin-right: 2em;
    }
    &-view {
      flex: 1;
    }
  }
  .table-class {
    color: #409eff;
    cursor: pointer;
  }
  .radio-box {
    display: flex;
    &-r {
      border: 1px solid #dcdfe6;
      border-radius: 100%;
      width: 14px;
      height: 14px;
      background-color: #fff;
      cursor: pointer;
      box-sizing: border-box;
    }
    &-t {
      font-size: 14px;
      padding-left: 10px;
    }
  }
  .radio-box-checkd {
    .radio-box-r {
      position: relative;
      background-color: #409eff;
    }
    .radio-box-r::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      width: 4px;
      height: 4px;
      background-color: #fff;
      transition: transform 0.15s ease-in;
      z-index: 2;
    }
    .radio-box-t {
      color: #409eff;
    }
  }
  .radio-box-disabled {
    cursor: not-allowed;
    .radio-box-r {
      cursor: not-allowed;
    }
  }
}
</style>

<style lang="scss">
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.add-policy-box {
  .from-box {
    .descriptions-class {
      display: flex;
      justify-content: space-between;
      background-color: #eee;
      margin-bottom: 12px;
      padding: 0 12px;
      height: 40px;
      line-height: 40px;
      max-width: 800px;
      border-radius: 8px;
      > div {
        flex: 1;
      }
    }
    .el-textarea__inner {
      background-color: #f3f3f3;
    }
    .descriptions-class2 {
      flex-wrap: wrap;
      height: auto;
      > div {
        width: 50%;
        flex: none;
      }
    }
    .el-form-item {
      margin-bottom: 24px;
      margin-right: 80px;
      .el-form-item__label {
        padding-bottom: 0;
      }
    }
    .form-item-t {
      width: 100%;
      .el-form-item__content {
        width: 100%;
        .el-textarea__inner {
          background-color: #f3f3f3;
        }
      }
    }
    .el-table__header-wrapper {
      background-color: #f0f4fb;
      overflow: hidden;
    }
    .has-gutter,
    .el-table__fixed-header-wrapper {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      background: #f0f4fb;
      tr {
        background-color: #f0f4fb;
        border-radius: 8px;
        overflow: hidden;
      }
      th {
        background-color: transparent;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }
    .el-table__body {
      tr {
        position: relative;
      }
      .table-del {
        position: absolute;
        top: 50%;
        right: -50px;
        transform: translateY(-50%);
        font-size: 30px;
        color: #c94242;
        cursor: pointer;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
    .el-table__row {
      .cell {
        color: #333;
        font-size: 14px;
      }
    }
  }
}
</style>