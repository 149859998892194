<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="80%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div>
      <div class="checked-all">
        <el-checkbox
          v-model="checked"
          :indeterminate="indeterminate"
          @change="handleAllChecked"
          >全部字段</el-checkbox
        >
      </div>
      <div class="checkbox-box">
        <div
          class="checkbox-view"
          v-for="(item, index) in listFieldData"
          :key="index"
        >
          <el-checkbox
            v-model="item.checked"
            @change="handleCheckedClike($event, index)"
            :disabled="item.required"
          >
            <span v-if="item.required" style="color: red">*</span>
            {{ item.desc }}
          </el-checkbox>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    listFieldInfo: {
      type: Array,
      default: () => [],
    },
    checkboxIds: {
      type: [Array, String],
      default: () => [],
    },
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v) {
          this.getList();
          this.cheCkedChange();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      checked: false,
      listFieldData: [],
      indeterminate: false,
    };
  },
  async created() {},
  computed: {
    // indeterminate() {
    //   let flag = false;
    //   this.listFieldData.map(val => {
    //     if (val.checked) {
    //       flag = true;
    //     }
    //   });
    //   return false;
    //   // if (this.listFieldInfo.length === this.listFieldData.filter(val => val.checked).length || this.listFieldData.filter(val => val.checked).length === 0) {
    //   //   if (this.listFieldData.filter(val => val.checked).length) {
    //   //     this.checked = true;
    //   //   } else {
    //   //     this.checked = false;
    //   //   }
    //   //   return false;
    //   // };
    //   // return true;
    // }
  },
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    getList() {
      this.listFieldData = this.listFieldInfo.map((val) => {
        if (!this.checkboxIds.includes(val.name) || val.required) {
          val.checked = true;
        } else {
          val.checked = false;
        }
        return val;
      });
    },
    handleAllChecked(e) {
      this.listFieldData.map((item, ind) => {
        if (!item.required) {
          this.listFieldData[ind].checked = e;
        }
      });
      this.cheCkedChange();
    },
    handleSubmit() {
      this.$emit(
        "changeHidelistField",
        this.listFieldData
          .filter((item) => !item.checked)
          .map((val) => val.name)
      );
      this.handleClose();
    },
    handleCheckedClike(e, ind) {
      this.listFieldData[ind].checked = e;
      this.cheCkedChange();
      this.$forceUpdate();
    },
    cheCkedChange() {
      if (
        this.listFieldInfo.length ===
          this.listFieldData.filter((val) => val.checked).length ||
        this.listFieldData.filter((val) => val.checked).length === 0
      ) {
        if (this.listFieldData.filter((val) => val.checked).length) {
          this.checked = true;
        } else {
          this.checked = false;
        }
        this.indeterminate = false;
      } else {
        this.checked = false;
        this.indeterminate = true;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  padding: 14px;
  .el-dialog__title {
    color: #fff;
  }
}
.checked-all {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  background: #f0f4fb;
  padding: 14px;
  border-bottom: 1px solid #ebeef5;
}
.checkbox-box {
  display: flex;
  flex-wrap: wrap;
  border-right: 1px solid #ebeef5;
  .checkbox-view {
    flex: 1;
    min-width: 20%;
    padding: 14px;
    border-bottom: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
  }
}
</style>
