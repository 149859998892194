<template>
  <div class="from-box">
    <div
      class="from-box-title from-box-title-float"
      :style="{ top: offsetTop + 'px', width: width + 'px' }"
      v-if="isFloatHead"
    >
      <div class="from-box-title-left">
        <img src="@/assets/images/policy/title-left.png" alt srcset />
        {{ title }}
      </div>
      <div class="from-box-title-right">
        <slot name="head-right" />
      </div>
    </div>
    <div
      :class="{ 'from-box-title': true, 'box-opacity': isFloatHead }"
      ref="from-box-title"
    >
      <div class="from-box-title-left">
        <img src="@/assets/images/policy/title-left.png" alt srcset />
        {{ title }}
      </div>
      <div class="from-box-title-right">
        <slot name="head-right" />
      </div>
    </div>
    <div style="height: 24px"></div>
    <div class="from-box-insurer">
      <div class="from-box-m">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    isFloatHead: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      offsetTop: 0,
      width: 0
    };
  },
  created() {
    this.$nextTick(() => {
      this.offsetTop = this.$refs['from-box-title'].offsetTop + 63;
      this.width = this.$refs['from-box-title'].offsetWidth + 48;
    });
  }
}
</script>

<style lang="scss" scoped>
.from-box {
  // padding: 12px 24px 0;
  margin-bottom: 12px;
  background: #ffffff;
  border-radius: 8px;
  &-title {
    display: flex;
    justify-content: space-between;
    margin: 12px 24px 0;
    border-bottom: 1px dashed #dedede;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  &-insurer {
    // padding-bottom: 24px;
    padding: 0 24px 24px;
    // border-bottom: 1px dashed #dedede;
    .el-table {
      overflow: visible;
    }
    .insurance-company {
      width: 600px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0 24px;
      div {
        font-size: 16px;
        font-weight: bold;
        color: #4278c9;
      }
    }
  }
  &-m {
    padding-left: 24px;
  }
}
.box-opacity {
  opacity: 0;
}
</style>